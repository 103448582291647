import React from 'react';
import { t } from "../locale";

interface NationalityDropdownProps {
  selectedNationality: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const NationalityDropdown: React.FC<NationalityDropdownProps> = ({ selectedNationality, onChange }) => {
  const nationalities = [
    { country: t('Germany'), nationality: "deutsch" },
    { country: t('Switzerland'), nationality: "che" },
    { country: t('Austria'), nationality: "aut" },
    { country: t('Australia'), nationality: "australian"},
    { country: t('France'), nationality: "fra" },
    { country: t('Italy'), nationality: "ita" },
    { country: t('Albania'), nationality: "shqiptare/albanian" },
    { country: t('Belgium'), nationality: "bel" },
    { country: t('Bosnia and Herzegovina'), nationality: "bih/бих" },
    { country: t('Bulgaria'), nationality: "българия/bgr" },
    { country: t('Montenegro'), nationality: "mne" },
    { country: t('Canada'), nationality: 'canadian/canadienne'},
    { country: t('Czech Republic'), nationality: "česká republika" },
    { country: t('Denmark'), nationality: "dnk" },
    { country: t('Estonia'), nationality: "est" },
    { country: t('Finland'), nationality: "fin" },
    { country: t('Greece'), nationality: "ελληνικΗ/hellenic" },
    { country: t('Netherlands'), nationality: "nederlandse" },
    { country: t('Croatia'), nationality: "hrv" },
    { country: t('Ireland'), nationality: "éireannach/irish" },
    { country: t('Kosovo'), nationality: "kosovar" },
    { country: t('Latvia'), nationality: "latvijas" },
    { country: t('Lithuania'), nationality: "lietuvos respublikos" },
    { country: t('Luxembourg'), nationality: "lux" },
    { country: t('Hungary'), nationality: "hun" },
    { country: t('Moldova'), nationality: "mda" },
    { country: t('Norway'), nationality: "norsk/norwegian" },
    { country: t('Poland'), nationality: "polskie" },
    { country: t('Portugal'), nationality: "prt" },
    { country: t('Romania'), nationality: "rou" },
    { country: t('North Macedonia'), nationality: "македонско/macedonian" },
    { country: t('Spain'), nationality: "esp" },
    { country: t('Serbia'), nationality: "Република Србија" },
    { country: t('Slovakia'), nationality: "svk" },
    { country: t('Slovenia'), nationality: "si" },
    { country: t('Sweden'), nationality: "svensk/swe" }
  ];

  return (
    <select
      id="nationality"
      className="form-control border-primary"
      value={selectedNationality}
      onChange={onChange}
      required={true}
    >
      <option value=""></option>
      {nationalities.map((item, index) => (
        <option key={index} value={item.nationality}>
          {item.country}
        </option>
      ))}
    </select>
  );
};

export default NationalityDropdown;