import { useState } from 'react'
import { Card, FormControl, Form, } from 'react-bootstrap'
import v from '../validators'
import { t } from '../locale'
import { DoorOpenFill } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'

function ForgotPassword() {

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [isEmailValid, setIsEmailValid] = useState(true)

    function sendPasswordResetEmail() {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/access/password-reset-email`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email
            })
        }).then(r => r.json())
            .then(r => {
                if (r.ok) {
                    navigate('/ds_id_verification/login')
                }
            })
    }

    function handleEmail(e: any) {
        setEmail(e.target.value.trim())
        setIsEmailValid(v.email(email))
    }
    function handleSubmit(e: any) {
        e.preventDefault()

        setIsEmailValid(v.email(email))

        if (v.email(email)) {
            sendPasswordResetEmail()
        }
    }

    return (
        <Card className='col-8 m-auto mt-4 w-25'>

            <Card.Header>
                <DoorOpenFill />
                <span className='p-1 align-middle'>
                    {t('getPasswordResetEmail')}
                </span>
            </Card.Header>

            <Card.Body>

                <Form onSubmit={handleSubmit}>

                    <FormControl id='email'
                        type='email'
                        isInvalid={!isEmailValid}
                        placeholder={t('email')}
                        value={email}
                        onChange={handleEmail}
                        onKeyUp={handleEmail}
                        autoComplete='on'
                        autoFocus />

                    <button type='submit'
                        className='mt-3 btn btn-primary mt-3 text-white'
                        onClick={handleSubmit}>{t('submit')}</button>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default ForgotPassword
