const de: any = {
  videoIdentification: 'Videoidentifikation',
  selfIdentifactionStep: 'Herzlich Willkommen bei der Videoidentifikation von DATA Security GmbH',
  paymentStep: 'Payment Card ',
  videoStep: 'Naslov za korak pravljenja videa i slike zadnje strane dokumenta',
  payment: 'Zahlung',
  wellDone: 'Gut gemacht!',
  identificationSuccessful: 'Identifizierung erfolgreich!',
  identificationFailed: 'Die Identifizierung ist fehlgeschlagen. Versuchen Sie es erneut.',
  captureDocumentText: 'Klicken Sie auf „Dokument erfassen“, um ein Foto des Dokuments aufzunehmen.',
  captureSelfText: 'Klicken Sie auf „Selbst erfassen“, um ein Foto von sich aufzunehmen.',
  captureDocumentButton: 'Dokument',
  captureSelfButton: 'Gesicht',
  continue: 'Weitermachen',
  startProcess: 'Prozess starten',
  holdDocument: 'Legen Sie das Dokument in den Rahmen: ',
  loading: 'Laden...',
  holdFace: 'Bringen Sie Ihr Gesicht in den Rahmen: ',
  wait: 'Warten...',
  radioButtonCard: 'Prüfen Sie, ob Sie mit Karte bezahlen möchten',
  radioButtonDirect: 'Klicken Sie hier, um das Ende der Identifizierung zu bezahlen',
  submit: 'Absenden',
  male: 'Herr',
  female: 'Frau',
  other: 'Herr/Frau',

  tipSentence1: 'Hier können Sie in wenigen Minuten Ihre Identifikation mit Personalausweis oder Reisepass durchführen.',
  schritt1: 'Schritt 1: ',
  tipSentence2: 'Erstellen Sie ein Foto von Ihrem Dokument und von Ihrem Gesicht',
  tipSentence3: 'Bitte folgende Punkte beachten:',
  tipSentence4: 'Um klare Bilder aufzunehmen, müssen Sie sich in einem gut beleuchteten Raum befinden. Vermeiden Sie Gegenlicht.',
  tipSentence5: 'Bringen Sie das Dokument näher an die Kamera und halten Sie es in den Rahmen.',
  tipSentence6: 'Klicken Sie auf den Button "Prozess starten". Das System wird nach 10 Sekunden ein Foto erstellen. Danach wird das Dokument verifiziert und Sie können anschließend ein Foto von Ihrem Gesicht erstellen.',
  tipSentence7: 'Halten Sie Ihr Gesicht in den ovalen Rahmen und klicken Sie auf den Button "Prozess starten". Das System wird nach 10 Sekunden ein Foto von Ihrem Gesicht erstellen.',
  tipSentence8: 'Warten Sie einige Sekunden, im Hintergrund werden die Daten abgeglichen und Sie können mit Schritt 2 beginnen, wenn die Überprüfung erfolgreich war.',
  tipSentence9: 'Sie können den Vorgang wiederholen, wenn die Überprüfung nicht erfolgreich war.',
  tipSentence10: 'Identifikation mittels Personalausweis',
  tipSentence11: 'Bitte ein Foto von der Rückseite des Dokuments erstellen',
  tipSentence12: 'Bitte ein Video, Dauer ca. 5-10 Sekunden, erstellen. Im Video muss Ihr Gesicht und die Vorderseite des Dokuments neben Ihrem Gesicht klar erkennbar sein und wir bitten Sie Ihren Vornamen, Nachnamen und Geburtsdatum zu nennen.',
  tipSentence13: 'Identifikation mittels Reisepass',
  tipSentence14: 'Bitte ein Video, Dauer ca. 5-10 Sekunden, erstellen. Im Video muss Ihr Gesicht und das Dokuments neben Ihrem Gesicht klar erkennbar sein und wir bitten Sie Ihren Vornamen, Nachnamen und Geburtsdatum zu nennen.',

  tipSentenceMobile5: 'Klicken Sie auf den Button "Upload Documet Image" und Sie können ein Foto Ihres Dokuments hochladen.',
  tipSentenceMobile6: 'Danach wird das Dokument verifiziert und Sie können anschließend ein Foto von Ihrem Gesicht erstellen.',
  tipSentenceMobile7: 'Klicken Sie auf den Button "Upload Self Image" und Sie können ein Foto von Ihrem Gesicht hochladen.',
  tipSentenceMobile8: 'Warten Sie einige Sekunden, im Hintergrund werden die Daten abgeglichen und Sie können mit Schritt 2 beginnen, wenn die Überprüfung erfolgreich war.',
  tipSentenceMobile9: 'Sie können den Vorgang wiederholen, wenn die Überprüfung nicht erfolgreich war.',

  schritt2: 'Schritt 2: ',
  schritt3: 'Schritt 3: ',
  tipSentence15: 'Sie erhalten eine Anweisung am Bildschirm und per E-Mail für die Überweisung von 0,01 € auf einem Konto der DATA Security GmbH',
  tipSentence16: 'Nach Eingang der Zahlung prüft ein geschulter Mitarbeiter der DATA Security GmbH den Vorgang und gibt diesen frei. Sie erhalten anschließend eine Mail inklusive Link zum Download der Nachweisdokumente für die erfolgreiche Videoidentifikation.',

  videoStep1: 'Klikom na start process prvo napravite fotografiju zadnje strane dokumenta.',
  videoStep2: 'Nakon napravljene slike postavite dokument pored lica i zadrzite tako nekoliko sekudni kako bi bio napravljen video.',

  videoStepMobile: 'Identifikation mittels Personalausweis',
  videoStepMobile1: 'Klicken Sie auf den Button "Upload Documet Image" und Sie können ein Foto von der Rückseite Ihres Dokuments hochladen.',
  videoStepMobile2: 'Klicken Sie auf den Button "Upload Video". Bitte ein Video, Dauer ca. 5-10 Sekunden, erstellen. Im Video muss Ihr Gesicht und die Vorderseite des Dokuments neben Ihrem Gesicht klar erkennbar sein und wir bitten Sie Ihren Vornamen, Nachnamen und Geburtsdatum zu nennen.',
  videoStepMobile3: 'Identifikation mittels Reisepass',
  videoStepMobile4: 'Klicken Sie auf den Button "Upload Video". Bitte ein Video, Dauer ca. 5-10 Sekunden, erstellen. Im Video muss Ihr Gesicht und die Vorderseite des Dokuments neben Ihrem Gesicht klar erkennbar sein und wir bitten Sie Ihren Vornamen, Nachnamen und Geburtsdatum zu nennen.',
  videoStepMobile5: 'Sie erhalten eine Anweisung am Bildschirm und per E-Mail für die Überweisung von 0,01 € auf einem Konto der DATA Security GmbH',
  videoStepMobile6: 'Nach Eingang der Zahlung prüft ein geschulter Mitarbeiter der DATA Security GmbH den Vorgang und gibt diesen frei. Sie erhalten anschließend eine Mail inklusive Link zum Download der Nachweisdokumente für die erfolgreiche Videoidentifikation.',
  uploadImageBack: 'Upload Document Image',
  uploadSelfImage: 'Upload Self Image',
  uploadVideo: 'Upload Video',
  startRecoding: 'Fertig. Beginnen Sie mit der Aufnahme, wenn Sie bereit sind.',
  
  paymentInstructions: 'Videoidentifikation – Überweisung von 0,01 €',
  paymentInstructionsMobile1: 'Videoidentifikation –',
  paymentInstructionsMobile2: 'Überweisung von 0,01 €',
  paymentStep1: 'Herzlichen Glückwunsch! Sie haben sich mit Ihrem Dokument erfolgreich verifiziert.',
  paymentStep2: 'Im nächsten Schritt bitten wir Sie um zeitnahe Überweisung von einem Betrag in Höhe von 0,01 € von Ihrem persönlichen Bankkonto.',
  paymentStep3: 'Danach können wir die Videoidentifikation erfolgreich abschließen und Ihnen die Nachweisdokumente zur Verfügung stellen.',
  achtung: 'Achtung',
  paymentStep4: 'Das Bankkonto von welchem Sie 0,01 € überweisen muss auf Ihren Namen lauten.',
  paymentStep5: 'Bankkonto für die Überweisung von 0,01 €:',
  paymentStep6: 'Empfänger: DATA Security GmbH',
  paymentStep7: 'IBAN: DE85 7115 0000 00202133 77',
  paymentStep8: 'Betreff / Verwendungszweck: ',

  accessDenied: 'Dieser Link ist nicht mehr gültig.',
  accessExpired: 'We regret to inform you that your identification request has expired. This means you will need to initiate a new request to proceed.',
  requestExpired: 'Request Expired',
  changeDeviceText: 'Wechseln Sie zu einem Mobiltelefon, um Bilder in besserer Qualität aufzunehmen.',

  gender: 'Anrede',
  userData: 'Benutzerdaten',
  firstName: 'Vorname',
  lastName: 'Nachname',
  email: 'E-Mail',
  dateOfBirth: 'Geburtsdatum',
  nationality: 'Staatsangehörigkeit',
  idNumber: 'Dokumentennummer – Personalausweis oder Reisepass',
  idNumberMobile1: 'Dokumentennummer – ',
  idNumberMobile2: 'Personalausweis oder Reisepass',

  ebanking: 'Führen Sie eine Zahlung per E-Banking durch',
  referenceNumber: 'Geben Sie diese Referenznummer unbedingt in der Beschreibung an',

  Afghanistan: 'Afghanistan',
  ÅlandIslands: 'Åland-Inseln',
  Albania: 'Albanien',
  Algeria: 'Algerien',
  AmericanSamoa: 'Amerikanisch-Samoa',
  Andorra: 'Andorra',
  Angola: 'Angola',
  Anguilla: 'Anguilla',
  Antarctica: 'Antarktis',
  AntiguaandBarbuda: 'Antigua und Barbuda',
  Argentina: 'Argentinien',
  Armenia: 'Armenien',
  Aruba: 'Aruba',
  Australia: 'Australien',
  Austria: 'Österreich',
  Azerbaijan: 'Aserbaidschan',
  Bahamas: 'Bahamas',
  Bahrain: 'Bahrain',
  Bangladesh: 'Bangladesch',
  Barbados: 'Barbados',
  Belarus: 'Belarus',
  Belgium: 'Belgien',
  Belize: 'Belize',
  Benin: 'Benin',
  Bermuda: 'Bermuda',
  Bhutan: 'Bhutan',
  BosniaandHerzegovina: 'Bosnien und Herzegowina',
  Botswana: 'Botswana',
  BouvetIsland: 'Bouvetinsel',
  Brazil: 'Brasilien',
  BritishIndianOceanTerritory: 'Britisches Territorium im Indischen Ozean',
  BruneiDarussalam: 'Brunei Darussalam',
  Bulgaria: 'Bulgarien',
  BurkinaFaso: 'Burkina Faso',
  Burundi: 'Burundi',
  CaboVerde: 'Cabo Verde',
  Cambodia: 'Kambodscha',
  Cameroon: 'Kamerun',
  Canada: 'Kanada',
  CaymanIslands: 'Kaimaninseln',
  CentralAfricanRepublic: 'Zentralafrikanische Republik',
  Chad: 'Tschad',
  Chile: 'Chile',
  China: 'China',
  ChristmasIsland: 'Weihnachtsinsel',
  Colombia: 'Kolumbien',
  Comoros: 'Komoren',
  Congo: 'Kongo',
  CookIslands: 'Cookinseln',
  CostaRica: 'Costa Rica',
  Croatia: 'Kroatien',
  Cuba: 'Kuba',
  Curaçao: 'Curaçao',
  Cyprus: 'Zypern',
  Czechia: 'Tschechien',
Denmark: 'Dänemark',
  Djibouti: 'Dschibuti',
  Dominica: 'Dominica',
  DominicanRepublic: 'Dominikanische Republik',
  Ecuador: 'Ecuador',
  Egypt: 'Ägypten',
  ElSalvador: 'El Salvador',
  EquatorialGuinea: 'Äquatorialguinea',
  Eritrea: 'Eritrea',
  Estonia: 'Estland',
  Eswatini: 'Eswatini',
  Ethiopia: 'Äthiopien',
  FaroeIslands: 'Färöer-Inseln',
  Fiji: 'Fidschi',
  Finland: 'Finnland',
  France: 'Frankreich',
  FrenchGuiana: 'Französisch-Guayana',
  FrenchPolynesia: 'Französisch-Polynesien',
  FrenchSouthernTerritories: 'Französische Süd- und Antarktisgebiete',
  Gabon: 'Gabun',
  Gambia: 'Gambia',
  Georgia: 'Georgien',
  Germany: 'Deutschland',
  Ghana: 'Ghana',
  Gibraltar: 'Gibraltar',
  Greece: 'Griechenland',
  Greenland: 'Grönland',
  Grenada: 'Grenada',
  Guadeloupe: 'Guadeloupe',
  Guam: 'Guam',
  Guatemala: 'Guatemala',
  Guernsey: 'Guernsey',
  Guinea: 'Guinea',
  Guyana: 'Guyana',
  Haiti: 'Haiti',
  HeardIslandandMcDonaldIslands: 'Heard und McDonaldinseln',
  HolySee: 'Heiliger Stuhl',
  Honduras: 'Honduras',
  HongKong: 'Hongkong',
  Hungary: 'Ungarn',
  Iceland: 'Island',
  India: 'Indien',
  Indonesia: 'Indonesien',
  Iraq: 'Irak',
  Ireland: 'Irland',
  IsleofMan: 'Isle of Man',
  Israel: 'Israel',
  Italy: 'Italien',
  Jamaica: 'Jamaika',
  Japan: 'Japan',
  Jersey: 'Jersey',
  Jordan: 'Jordanien',
  Kazakhstan: 'Kasachstan',
  Kenya: 'Kenia',
  Kiribati: 'Kiribati',
  Kuwait: 'Kuwait',
  Kyrgyzstan: 'Kirgisistan',
  Latvia: 'Lettland',
  Lebanon: 'Libanon',
  Lesotho: 'Lesotho',
  Liberia: 'Liberia',
  Libya: 'Libyen',
  Liechtenstein: 'Liechtenstein',
  Lithuania: 'Litauen',
  Luxembourg: 'Luxemburg',
  Macao: 'Macao',
  Madagascar: 'Madagaskar',
  Malawi: 'Malawi',
  Malaysia: 'Malaysia',
  Maldives: 'Malediven',
  Mali: 'Mali',
  Malta: 'Malta',
  MarshallIslands: 'Marshallinseln',
  Martinique: 'Martinique',
  Mauritania: 'Mauretanien',
  Mauritius: 'Mauritius',
  Mayotte: 'Mayotte',
  Mexico: 'Mexiko',
  Monaco: 'Monaco',
  Mongolia: 'Mongolei',
  Montenegro: 'Montenegro',
  Montserrat: 'Montserrat',
  Morocco: 'Marokko',
  Mozambique: 'Mosambik',
  Myanmar: 'Myanmar',
  Namibia: 'Namibia',
  Nauru: 'Nauru',
  Nepal: 'Nepal',
  Netherlands: 'Niederlande',
  NewCaledonia: 'Neukaledonien',
  NewZealand: 'Neuseeland',
  Nicaragua: 'Nicaragua',
  Niger: 'Niger',
  Nigeria: 'Nigeria',
  Niue: 'Niue',
  NorfolkIsland: 'Norfolkinsel',
  NorthMacedonia: 'Nordmazedonien',
  NorthernMarianaIslands: 'Nördliche Marianen',
  Norway: 'Norwegen',
  Oman: 'Oman',
  Pakistan: 'Pakistan',
  Palau: 'Palau',
  Panama: 'Panama',
  PapuaNewGuinea: 'Papua-Neuguinea',
  Paraguay: 'Paraguay',
  Peru: 'Peru',
  Philippines: 'Philippinen',
  Pitcairn: 'Pitcairninseln',
  Poland: 'Polen',
  Portugal: 'Portugal',
  PuertoRico: 'Puerto Rico',
  Qatar: 'Katar',
  Romania: 'Rumänien',
  RussianFederation: 'Russland',
  Rwanda: 'Ruanda',
  Réunion: 'Réunion',
  SaintBarthélemy: 'Saint-Barthélemy',
  SaintLucia: 'St. Lucia',
  SaintPierreandMiquelon: 'Saint-Pierre und Miquelon',
  SaintVincentandtheGrenadines: 'St. Vincent und die Grenadinen',
  Samoa: 'Samoa',
  SanMarino: 'San Marino',
  SaoTomeandPrincipe: 'São Tomé und Príncipe',
  SaudiArabia: 'Saudi-Arabien',
  Senegal: 'Senegal',
  Serbia: 'Serbien',
  Seychelles: 'Seychellen',
  SierraLeone: 'Sierra Leone',
  Singapore: 'Singapur',
  Slovakia: 'Slowakei',
  Slovenia: 'Slowenien',
  SolomonIslands: 'Salomonen',
  Somalia: 'Somalia',
  SouthAfrica: 'Südafrika',
  SouthGeorgiaandtheSouthSandwichIslands: 'Südgeorgien und die Südlichen Sandwichinseln',
  SouthSudan: 'Südsudan',
  Spain: 'Spanien',
  SriLanka: 'Sri Lanka',
  Sudan: 'Sudan',
  Suriname: 'Suriname',
  SvalbardandJanMayen: 'Svalbard und Jan Mayen',
  Sweden: 'Schweden',
  Switzerland: 'Schweiz',
  SyrianArabRepublic: 'Syrien',
  Tajikistan: 'Tadschikistan',
  Thailand: 'Thailand',
  Togo: 'Togo',
  Tokelau: 'Tokelau',
  Tonga: 'Tonga',
  TrinidadandTobago: 'Trinidad und Tobago',
  Tunisia: 'Tunesien',
  Turkey: 'Türkei',
  Turkmenistan: 'Turkmenistan',
  TurksandCaicosIslands: 'Turks- und Caicosinseln',
  Tuvalu: 'Tuvalu',
  Uganda: 'Uganda',
  Ukraine: 'Ukraine',
  UnitedArabEmirates: 'Vereinigte Arabische Emirate',
  UnitedKingdomofGreatBritainandNorthernIreland: 'Vereinigtes Königreich',
  UnitedStatesofAmerica: 'Vereinigte Staaten',
  Uruguay: 'Uruguay',
  Uzbekistan: 'Usbekistan',
  Vanuatu: 'Vanuatu',
  VietNam: 'Vietnam',
  WesternSahara: 'Westsahara',
  Yemen: 'Jemen',
  Zambia: 'Sambia',
  Zimbabwe: 'Simbabwe'
}

export default de
