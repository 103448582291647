import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import { useRef, useState, useEffect } from "react";
import { t } from "../locale";
import {
  captureSnapshot,
  CameraComponent,
  CameraComponent2,
  imageComparing,
  checkDocumentData,
  saveImage,
  createJob
} from "../helpers/helpers";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from "react-router-dom";
import piexif from "piexif-ts"
import QRCodeModal from "../components/QRCodeModal";


let selfImageData: string;
let documentImageData: string;
let documentImageDataDocumentAnalyze: string;
let countDocCheck = 0;

function Index() {
  const navigate = useNavigate();
  const bottomRef = useRef<HTMLDivElement>(null);

  const pathSegments = window.location.pathname.split("/");
  const companyId = pathSegments[1];
  const requestId = pathSegments[2];
  const passport = pathSegments[4]

  const [hasAccess, setHasAccess] = useState(true);
  const [expiredPage, setExpiredPage] = useState(false);

  const [facingMode, setFacingMode] = useState('environment');
  const [isMobile, setIsMobile] = useState(false);

  const [frontButton, setFrontButton] = useState('')
  const [backButton, setBackButton] = useState('disabled')
  const [frontId, setFrontId] = useState(true)
  const [frontBorderColor, setFrontBorderColor] = useState('border-primary')
  const [backBorderColor, setBackBorderColor] = useState('border-primary')
  const [secondsLeftFront, setSecondsLeftFront] = useState(10)
  const [secondsLeftBack, setSecondsLeftBack] = useState(10)
  const [timeLabelFront, setTimeLabelFront] = useState(true)
  const [timeLabelBack, setTimeLabelBack] = useState(false)
  const [labelWait, setLabelWait] = useState(false)
  const [labelWaitBack, setLabelWaitBack] = useState(false)
  const [label, setLabel] = useState(t('failed'))
  const [labelColor, setLabelColor] = useState('text-primary')
  const [spaceHeight, setSpaceHeight] = useState('mt-5')
  const [blinkLabel, setBlinkLabel] = useState('')
  const [captured, setCaptured] = useState(false)
  const [capturedBack, setCapturedBack] = useState(false)
  const [mobileInstructionStyle, setMobileInstructionStyle] = useState('')
  const [verification, setVerification] = useState(false)

  const [frontButtonLabel, setFrontButtonLabel] = useState('')
  const [backButtonLabel, setBackButtonLabel] = useState('')
  const [orientation, setOrientation] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [qrCodeSrc, setQrCodeSrc] = useState('')

  const fileInputDocument = useRef<HTMLInputElement | null>(null);
  const fileInputBackDocument = useRef<HTMLInputElement | null>(null);
  let stream: any;
  useEffect(() => {
    fetchData();
    handleDeviceDetection();
    qrCode()
  },
    []
  )

  const init = () => {
    setFacingMode('environment')
    setLabelWait(false)
    setTimeLabelFront(true)
    setSecondsLeftFront(10);
    setSecondsLeftBack(10);
    setFrontBorderColor('border-primary');
    setCaptured(false)
    setCapturedBack(false)
    setFrontButton('')
  }

  CameraComponent(facingMode, isMobile);


  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/request/data/all`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            companyId: companyId,
            requestId: requestId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      if (data) {
        if (data.status === "EXPIRED") {
          setExpiredPage(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setHasAccess(false);
    }
  }

  const handleDeviceDetection = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log(userAgent);
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
    const isTablet = /(ipad|macintosh|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

    if (isMobile) {
      setIsMobile(true)
      setSpaceHeight('')
      setMobileInstructionStyle('mt-4')
      setFrontButtonLabel(t('buttonFrontUploadMobile'))
      setBackButtonLabel(t('buttonFrontUploadMobile'))
    } else if (isTablet) {
      setIsMobile(true)
      setSpaceHeight('')

    } else {
      setFrontButtonLabel(t('buttonFrontUpload'))
      setBackButtonLabel(t('backFrontUpload'))
    }
  }

  const changeCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  }

  function scrollToBottom() {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 0);
  }

  const handleDocumentUpload = () => {
    if (fileInputDocument.current) {
      fileInputDocument.current.click();
    }
  }

  const handleBackDocumentUpload = () => {
    if (fileInputBackDocument.current) {
      fileInputBackDocument.current.click();
    }
  }

  const stripExif = (base64Image: string): string => {
    try {
      return piexif.remove(base64Image);
    } catch (e) {
      console.error('Error removing EXIF:', e);
      return base64Image; // Ako nešto pođe po zlu, vrati originalnu sliku
    }
  };

  const documentUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    countDocCheck++;
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string
        documentImageDataDocumentAnalyze = base64String.replace(/^data:image\/(jpg|jpeg|png);base64,/, '')
        saveImage(documentImageDataDocumentAnalyze, companyId, requestId, true, 'document')
      };
      reader.readAsDataURL(file);

      setCaptured(true)
      setFrontButton('disabled')
      resizeImage(file, 800, 600, async (resizedBase64) => {
        const base64String = resizedBase64.replace(/^data:image\/(jpg|jpeg|png);base64,/, '')
        documentImageData = stripExif(base64String)
        saveBase64ToLocalStorage('documentImage', documentImageData)
        setTimeLabelFront(false)

        const docImageElement = document.getElementById("imageDocument") as HTMLImageElement
        if (docImageElement) {
          docImageElement.src = "data:image/png;base64," + resizedBase64

          docImageElement.style.height = '200px'
        }

        setBlinkLabel('blinking-label')

        setLabelWait(true)
        setLabel(t('waitDocument'))
        await compareImage()
      });
    }
    scrollToBottom()
  }

  const saveBase64ToLocalStorage = (key: string, base64: string) => {
    try {
      localStorage.setItem(key, base64);
      console.log('Base64 string saved successfully.');
    } catch (error) {
      console.error('Error saving Base64 string:', error);
    }
  };

  const backDocumentUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        documentImageDataDocumentAnalyze = base64String.replace(/^data:image\/(jpg|jpeg|png);base64,/, '');
        saveImage(documentImageDataDocumentAnalyze, companyId, requestId, false, 'document');
      };
      reader.readAsDataURL(file);

      setCapturedBack(true)
      resizeImage(file, 800, 600, async (resizedBase64) => {
        const base64String = resizedBase64.replace(/^data:image\/(jpg|jpeg|png);base64,/, '');
        documentImageData = base64String;
        setTimeLabelFront(false)

        const backDocImageElement = document.getElementById("backImageDocument") as HTMLImageElement;
        if (backDocImageElement) {
          backDocImageElement.src = "data:image/png;base64," + resizedBase64;

          backDocImageElement.style.height = '200px';
        }

        setBackBorderColor('border-success');
        setLabelWaitBack(true)

        setVerification(true)
      });
    }
    scrollToBottom()
  }

  const resizeImage = (file: File, maxWidth: number, maxHeight: number, callback: (base64: string) => void) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result as string;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // Proveri proporcije i prilagodi veličinu
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        // Postavi canvas dimenzije
        canvas.width = width;
        canvas.height = height;

        // Iscrtaj sliku na canvas sa smanjenom rezolucijom
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height);

          // Konvertuj canvas nazad u Base64 format
          const base64String = canvas.toDataURL('image/png'); // Može biti i 'image/png'
          const imageData = base64String.replace(/^data:image\/png;base64,/, '');
          callback(imageData);
        }
      };
    };

    reader.readAsDataURL(file);
  }

  const captureFrontId = async () => {
    countDocCheck++;

    setFrontButton('disabled')
    const video = document.getElementById("videoElement") as HTMLVideoElement;
    let capturedDocImageElement: HTMLImageElement | null = null;

    if (!isMobile) {
      const countdown = setInterval(() => {
        setSecondsLeftFront(prevSeconds => {
          if (prevSeconds <= 1) {
            clearInterval(countdown);
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    setCaptured(true)
    setFrontButton('disabled')
    setTimeout(async () => {
      if (!isMobile) {
        const result = await captureSnapshot(
          video,
          "DOCUMENT",
          selfImageData,
          documentImageData
        );
        saveImage(result.documentImageData, companyId, requestId, true, 'document');

        setBlinkLabel('blinking-label')
        setLabelWait(true)
        setLabel(t('waitDocument'))
        setTimeLabelFront(false)
        scrollToBottom();

        documentImageData = result.documentImageData;
        saveBase64ToLocalStorage('documentImage', documentImageData)
        if (!capturedDocImageElement) {
          capturedDocImageElement = document.getElementById("imageDocument") as HTMLImageElement;
        }
        if (capturedDocImageElement) {
          capturedDocImageElement.src = "data:image/png;base64," + documentImageData;
        }

      }

      compareImage()


    }, 10000);
  }

  const captureBackId = async () => {
    const video = document.getElementById("videoElement") as HTMLVideoElement;

    let capturedBackDocImageElement: HTMLImageElement | null = null;
    setBackButton('disabled')
    const countdown = setInterval(() => {
      setSecondsLeftBack(prevSeconds => {
        if (prevSeconds <= 1) {
          clearInterval(countdown); // Change border color to green when countdown ends
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    setCapturedBack(true)
    setTimeout(async () => {
      const result = await captureSnapshot(
        video,
        "DOCUMENT",
        selfImageData,
        documentImageData
      );

      saveImage(result.documentImageData, companyId, requestId, false);

      if (!capturedBackDocImageElement) {
        capturedBackDocImageElement = document.getElementById("backImageDocument") as HTMLImageElement;
      }
      if (capturedBackDocImageElement) {
        capturedBackDocImageElement.src = "data:image/png;base64," + result.documentImageData;
      }

      setBackBorderColor('border-success');
      setLabelWaitBack(true)
      setVerification(true)

    }, 10000);
  }

  const compareImage = async () => {
    let capturedDocImageElement: HTMLImageElement | null = null;
    capturedDocImageElement = document.getElementById("imageDocument") as HTMLImageElement;

    let data;
    if (isMobile) {
      data = await checkDocumentData(documentImageDataDocumentAnalyze, requestId, companyId);
    } else {
      data = await checkDocumentData(documentImageData, requestId, companyId);
    }

    if (data.validation === true) {
      // if (true) {
      changeCamera();
      setFrontBorderColor('border-success');
      setTimeLabelBack(true)
      setFrontId(false)
      setLabelWait(true)
      setCaptured(true)
      setLabelColor('text-success')
      setLabel(t('success'))
      setTimeLabelFront(false)
      setTimeLabelBack(true)
      setFrontButton('disabled')
      setBackButton('')
      setBlinkLabel('')
    } else {
      if (countDocCheck === 2) {
        setFrontBorderColor('border-danger')
        setLabelColor('text-danger')
        setBlinkLabel('')
        setLabel(t('failed'))
        setSecondsLeftFront(0);
        setFrontButton('disabled')
        setShowModal(true)

        setTimeout(() => {
          init();
        }, 7000);
      } else {
        
        setFrontButton('disabled')
        setBlinkLabel('')
        setLabelColor('text-danger')
        setLabel(t('tryAgain'))
        setFrontBorderColor('border-danger')
        
        setTimeout(() => {
          init();
        }, 5000);
      }
    }
  }

  const qrCode = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/qrcode`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: process.env.REACT_APP_URL + `/${companyId}/${requestId}/si/${passport}/p`
        }),
      }
    );

    const res = await response.json()
    setQrCodeSrc(res.qrCode)
  }

  const back = () => {
    navigate(`/${companyId}/${requestId}/p`)
  }

  const next = () => {
    navigate(`/${companyId}/${requestId}/face_identify`)
  }

  const handleClose = () => {
    setShowModal(false);
    window.location.reload(); 
  };
  return (
    <>
      <div>
        <QRCodeModal qrCode={qrCodeSrc} show={showModal} onClose={handleClose}></QRCodeModal>
      </div>
      <Container className="container-fluid px-sm-5">
        <Col className="col-12 px-0 bg-white d-flex d-sm-none sticky-top z-0">
          <div className="d-flex flex-row flex-grow-1 align-items-between align-items-sm-start px-2 pe-3 pt-3">
            <ul className="nav nav-pills flex-row flex-nowrap flex-shrink-1 flex-grow-1 mb-0 justify-content-between align-items-center"
              id="menu">
              <li className="nav-item">
                <a href={`/${companyId}/${requestId}/p`} className="nav-link px-sm-0 px-2">
                  <img src="/images/logo/Step 1 Done.png" className="img-fluid step-numbers" />
                </a>
              </li>
              <li>
                <a href="step2.html" className="nav-link px-sm-0 px-2">
                  <img src="/images/logo/Step 2 Active.png" className="img-fluid step-numbers" />
                </a>
              </li>
              <li>
                <a href="step3.html" className="nav-link px-sm-0 px-2">
                  <img src="/images/logo/Step 3 Next.png" className="img-fluid step-numbers" />
                </a>
              </li>
              <li>
                <a href="step4.html" className="nav-link px-sm-0 px-2">
                  <img src="/images/logo/Step 4 Next.png" className="img-fluid step-numbers" />
                </a>
              </li>
              <li>
                <a href="step5.html" className="nav-link px-sm-0 px-2">
                  <img src="/images/logo/Step 5 Next.png" className="img-fluid step-numbers" />
                </a>
              </li>
            </ul>
          </div>
        </Col>
      </Container>
      {hasAccess ? (
        <Container hidden={expiredPage} className={`overflow-hidden ${spaceHeight}`}>
          <Row className="row overflow-auto">
            <Col className={`col-12 col-sm-3 col-xl-2 px-0 bg-white d-none d-sm-flex sticky-top pt-4 left-sidebar ${spaceHeight}`}>
              <div className={`d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-0 pt-2 text-white`}>
                <ul className="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start"
                  id="menu">
                  <li className="mb-4 nav-item">
                    <a href={`/${companyId}/${requestId}/p`}
                      className="nav-link px-sm-0 px-2">
                      <img src="/images/logo/Step 1 Done.png"
                        className="img-fluid step-numbers" />
                      <span
                        className="ms-1 d-none d-sm-inline text-success">{t('personalData')}</span>
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href=""
                      className="nav-link px-sm-0 px-2">
                      <img src="/images/logo/Step 2 Active.png"
                        className="img-fluid step-numbers" />
                      <span
                        className="ms-1 d-none d-sm-inline text-primary">{t('documentVerification')}</span>
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href=""
                      className="nav-link px-sm-0 px-2">
                      <img src="/images/logo/Step 3 Next.png"
                        className="img-fluid step-numbers" />
                      <span
                        className="ms-1 d-none d-sm-inline text-gray-light">{t('faceVerification')}</span>
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href=""
                      className="nav-link px-sm-0 px-2">
                      <img src="/images/logo/Step 4 Next.png"
                        className="img-fluid step-numbers" />
                      <span
                        className="ms-1 d-none d-sm-inline text-gray-light">{t('videoVerification')}</span>
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href=""
                      className="nav-link px-sm-0 px-2">
                      <img src="/images/logo/Step 5 Next.png"
                        className="img-fluid step-numbers" />
                      <span
                        className="ms-1 d-none d-sm-inline text-gray-light">{t('accountVerification')}</span>
                    </a>
                  </li>
                  <li>
                    <button
                      className="btn btn-success btn-lg text-white"
                      type="submit" onClick={back}>{t('back')} «
                    </button>

                  </li>
                </ul>
              </div>
            </Col>

            <Col className={`d-flex flex-column h-sm-100 px-sm-5 ${spaceHeight}`}>
              <main className={`row overflow-auto px-sm-5 position-relative ${spaceHeight}`}>
                <Col className="pt-4 pt-sm-5 text-center text-sm-start mt-n1">
                  <h4 className="mb-4">{t('titleVideoIdentify')}</h4>
                  <Row className="mt-5 mt-sm-0">
                    <div className={`col-sm-5 ${mobileInstructionStyle}`}>
                      <h5>{t('titleFrontId')}</h5>
                      {!isMobile &&
                        <>
                          <div id='videoContainer' className={`rounded-3 border ${frontBorderColor} border-3 id-frame w-auto`}>
                            {frontId && <video className="h-100 w-100 video-element " id="videoElement" autoPlay muted></video>}
                            {timeLabelFront && (secondsLeftFront !== 0 && <span className="text-primary h5">{secondsLeftFront}</span>)}

                          </div>
                        </>

                      }
                      <div className="text-center mt-2">
                        <Button
                          id='startProcessButton'
                          className={`btn btn-primary rounded-pill mt-2 d-none d-sm-inline ${frontButton}`}
                          onClick={captureFrontId}
                          style={{ textTransform: 'none' }}
                        >
                          {t('buttonFrontCapture')}
                        </Button>
                        <p className="my-2 d-none d-sm-block fs-5">{t('or')}</p>
                        <input
                          type="file"
                          accept="image/*;capture=camera"
                          onChange={documentUpload}
                          ref={fileInputDocument}
                          style={{ display: 'none' }}
                        />
                        <Button
                          className={`btn btn-primary rounded-pill mt-2 mt-sm-0 ${frontButton}`}
                          onClick={handleDocumentUpload}
                          style={{ textTransform: 'none' }}
                        >
                          {frontButtonLabel}
                        </Button>
                      </div>
                      <div className="mt-4 mb-4">
                        {isMobile && labelWait && <span className={`text-primary h4 ${blinkLabel} ${labelColor}`}>{label}</span>}
                      </div>


                      {captured && <div className={`text-center mt-2 rounded-3 border border-3 id-frame w-auto ${frontBorderColor}`}>
                        <img
                          id="imageDocument"
                          className={`w-100 h-100 object-fit-cover`}
                          alt=""
                        />
                        <div className="mt-2">
                          {!isMobile && labelWait && <span className={`text-primary h5 ${blinkLabel} ${labelColor}`}>{label}</span>}
                        </div>

                      </div>
                      }

                    </div>
                    <div className={`col-sm-5 ${mobileInstructionStyle}`}>
                      <h5>{t('titleBackId')}</h5>
                      {!isMobile &&
                        <div className={`rounded-3 border ${backBorderColor} border-3 id-frame w-auto`}>
                          <video className="h-100 w-100 video-element " id="videoElement" autoPlay muted></video>
                          {timeLabelBack && (secondsLeftBack !== 0 && <span className="text-primary h5">{secondsLeftBack}</span>)}
                          <div></div>
                        </div>
                      }
                      <div className="text-center mt-2">
                        <Button className={`btn btn-primary rounded-pill mt-2 d-none d-sm-inline ${backButton}`}
                          onClick={captureBackId}
                          style={{ textTransform: 'none' }}>{t('backFrontCapture')}
                        </Button>
                        <p className="my-2 d-none d-sm-block fs-5">{t('or')}</p>
                        <input
                          type="file"
                          accept="image/*;capture=camera"
                          onChange={backDocumentUpload}
                          ref={fileInputBackDocument}
                          style={{ display: 'none' }}
                        />

                        <Button className={`btn btn-primary rounded-pill mt-2 mt-sm-0 ${backButton}`}
                          onClick={handleBackDocumentUpload}
                          style={{ textTransform: 'none' }}>{backButtonLabel}</Button>
                      </div>
                      {capturedBack &&
                        <div className={`text-center mt-4 rounded-3 border border-3 id-frame w-auto ${backBorderColor}`}>
                          <img
                            id="backImageDocument"
                            className="w-100 h-100 object-fit-cover"
                            alt=""
                          />
                          <div className="mt-2">
                            {!isMobile && labelWaitBack && <span className={`text-primary h5 ${blinkLabel} ${labelColor}`}>{label}</span>}
                          </div>
                        </div>
                      }
                    </div>
                  </Row>
                  {verification && <div className="d-flex justify-content-between">
                    {!isMobile && <button
                      className="btn btn-success btn-lg text-white ms-auto mt-5"
                      type="submit" onClick={next}>{t('nextStep')} »
                    </button>}
                    {isMobile && <button
                      className="btn btn-success btn-lg text-white mt-4 ms-auto"
                      type="submit" onClick={next}>{t('nextStep')} »
                    </button>}
                  </div>}
                  <p className="my-5">&nbsp;</p>



                  <button className="btn btn-sm btn-outline-secondary rounded-pill position-absolute button-instructions "
                    type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight">{t('instructions')}</button>

                </Col>
                <div className="offcanvas offcanvas-end" id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel">
                  <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">{t('instructions')}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                      aria-label="Close"></button>
                  </div>
                  <div className="offcanvas-body">
                    <p className="mb-4">{t('bestResults')}</p>
                    <ul className="text-start list-unstyled mb-4">
                      <li>✔️ {t('firstInstruction')}</li>
                      <li>✔️ {t('secondInstruction')}</li>
                      <li>✔️ {t('thirdInstruction')}</li>
                    </ul>
                    <p className="mb-4">{t('example')}</p>
                    <p className="mb-0">{t('frontSide')}</p>
                    <img src="/images/Front id.jpg" className="img-fluid mt-2" alt="" />
                    <p className="mb-0">{t('backSide')}</p>
                    <img src="/images/Back is.jpg" className="img-fluid" alt="" />

                  </div>
                </div>
              </main>
            </Col>


          </Row>
        </Container>
      ) : (
        <Alert variant="danger" className="mt-3">
          {t("accessDenied")}
        </Alert>
      )}


      <div className="py-2" hidden={!expiredPage}>
        <h4>{t("requestExpired")}</h4>
        <p>{t("accessExpired")}</p>
      </div>
    </>
  );
};

export default Index;