import { Card, Container, NavDropdown, Navbar } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import { Translate } from 'react-bootstrap-icons'
import { changeLocale, locales, getLocale } from './locale'
import KAlert from './components/KAlert'
import { useState } from 'react'
import { t } from "./locale";

function Layout() {

  const [kAlert, setKAlert] = useState(window.kAlert)
  const [showKAlert, setShowKAlert] = useState(false)
  const currentLocale: string = getLocale()
  function handleLocaleChange(locale: string) {
    locale !== currentLocale && changeLocale(locale)
  }

  return (
    <>
    <div className="d-flex flex-column min-vh-100">
        <div className="flex-grow-1">
      <Card className='border-0'>
        <Card.Header className='border-bottom py-1'>
          <Container className='d-flex flex-wrap align-items-center justify-content-between'>
            {/* Logo and Title */}
            <div className='d-flex align-items-center flex-grow-1'>
              <img alt='logo' src='/logo.png' className='px-md-2 px-lg-2 py-2' />
              <h4 className='mx-3 my-0'>{t("videoIdentification")}</h4>
            </div>

            {/* Navbar for Locale Selection */}
            <Navbar className='ms-auto d-flex align-items-center'>
              <NavDropdown
                title={
                  <>
                    <img
                      src={`/flags/${currentLocale}.png`}
                      alt={`${currentLocale} flag`}
                      style={{ width: '14px', height: '9px', marginRight: '4px' }}
                    />
                    {(locales as any)[currentLocale]}
                  </>
                }
              >
                {Object.entries(locales).map(([locale, language]) => (
                  <NavDropdown.Item key={locale} onClick={() => handleLocaleChange(locale)}>
                    <img
                      src={`/flags/${locale}.png`}
                      alt={`${language} flag`}
                      style={{ width: '14px', height: '9px', marginRight: '4px' }}
                    />
                    {language}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Navbar>
          </Container>
        </Card.Header>

        <Card.Body>
          <Container>
            <KAlert show={showKAlert} dismissible={true} variant={kAlert?.variant} message={kAlert?.message} />
            <Outlet />
          </Container>
        </Card.Body>
      </Card>

      {/* Responsive Footer */}
      
          {/* Your main content goes here */}
        </div>
        <footer className="border-top bg-light mt-auto">
          <div className="container d-flex flex-wrap justify-content-between py-2">
            <small className="text-muted">
              © {new Date().getFullYear()} DATA Security GmbH. Alle Rechte vorbehalten.
            </small>
            <div className="d-flex">
              <small className="me-3">
                <a href="https://data-security.one/impressum/" className="text-secondary text-decoration-none fw-bold">Impressum</a>
              </small>
              <small>
                <a href="https://data-security.one/datenschutzhinweise/" className="text-secondary text-decoration-none fw-bold">Datenschutz</a>
              </small>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Layout