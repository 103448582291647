import { useEffect, useCallback } from "react";
import { STATUS_INITIATED } from "../utils/constants";
/**
 *
 * Capture snapshot from web camera (document image, selfy image)
 *
 * @param video
 * @param imageType
 * @param selfImageData
 * @param documentImageData
 * @param capturedImagesCount
 * @returns
 */
export async function captureSnapshot(video: HTMLVideoElement, imageType: string, selfImageData: string, documentImageData: string) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context?.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataUrl = canvas.toDataURL('image/png')
    const imageData = dataUrl.replace(/^data:image\/png;base64,/, '');

    if (imageType === 'SELF') {
        selfImageData = imageData;
    } else if (imageType === 'DOCUMENT') {
        documentImageData = imageData;
    }

    return { selfImageData, documentImageData };
}

/**
 *
 * @param imageType
 * @param selfImageData
 * @param documentImageData
 * @returns
 */
export function getCapturedImageData(imageType: string, selfImageData: string, documentImageData: string) {
    return imageType === 'SELF' ? selfImageData : documentImageData
}

/**
 *
 * @param selfImageData
 * @param documentImageData
 * @param companyId
 * @param requestId
 * @param toggleSelfIdentification
 * @returns
 */
export async function selfIdentificationRequest(selfImageData: string, documentImageData: string, companyId: string, requestId: string) {

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/self-identification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                companyId: companyId,
                requestId: requestId,
                documentImage: documentImageData,
                selfImage: selfImageData
            })
        });

        const responseData = response.json();
        return responseData;
    } catch (error) {
        console.error('Error Self Identification: ', error);
    }

}

export async function getReferenceNumber(companyId: string, requestId: string) {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/v1/request/data", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ requestId, companyId })
        });

        if (response.ok) {
            const data = await response.json();
            return data['referenceNumber'];
        } else {
            return '';
        }
    } catch (error) {
        console.error("Error while loading reference nuber: ", error);
    }
}

export async function checkRequest(companyId: string, requestId: string): Promise<boolean> {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/v1/request/data", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ requestId, companyId })
        });
        return response.status === 404 ? false : true;
    } catch (error) {
        // Handle any errors, such as network issues or server errors
        console.error("Error while checking request:", error);
        return false;
    }
}

export function formatDate(date: Date | null) {
    if (!date) return "";
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}.${month}.${year}`
}

export function parseDate(date: string) {
    if (!date) return null;
    let [day, month, year] = date.split(/[-./]/); // Split by any of the common date separators
    return new Date(`${year}-${month}-${day}`);
}

export async function updateStatus(companyId: string, requestId: string, status: string) {
    try {
        await fetch(`${process.env.REACT_APP_API_URL}/api/v1/status/update`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                companyId: companyId,
                requestId: requestId,
                status: status,
            }),
        });
    } catch (err) {
        console.error("Update request status failed: " + err);
    }
}

export async function CameraComponent(facingMode: any, device: any) {

    const streamCamVideo = useCallback(async () => {
        try {
            const video = document.getElementById("videoElement") as HTMLVideoElement;
            let constraints;
            if (device) {
                constraints = {
                    audio: false,
                    video: {
                        width: video.videoWidth, // pokušaj sa što većom vrednošću
                        height: video.videoHeight,
                        facingMode: { ideal: facingMode }
                    }
                };
            } else {
                constraints = {
                    audio: false,
                    video: {
                        width: { ideal: 2560 }, // pokušaj sa što većom vrednošću
                        height: { ideal: 1440 },
                        facingMode: { ideal: facingMode }
                    }
                };
            }

            const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);


            if (video) {
                video.srcObject = mediaStream;
                video.controls = false;
                video.onloadedmetadata = function (e) {
                    video.play();
                };
            }

        } catch (err) {
            console.log(err);
        }
    }, [facingMode]);

    useEffect(() => {

        streamCamVideo();
        return () => {
            const video = document.getElementById("videoElement") as HTMLVideoElement;
            if (video && video.srcObject) {
                const tracks = (video.srcObject as MediaStream).getTracks();
                tracks.forEach(track => track.stop());
            }
        };
    }, [streamCamVideo]);
}

export async function CameraComponent2(facingMode: any, device: any) {

    const streamCamVideo = useCallback(async () => {
        try {

            const video = document.getElementById("videoElement2") as HTMLVideoElement;
            let constraints;
            if (device) {
                constraints = {
                    audio: false,
                    video: {
                        width: video.videoWidth, // pokušaj sa što većom vrednošću
                        height: video.videoHeight,
                        facingMode: { ideal: facingMode }
                    }
                };
            } else {
                constraints = {
                    audio: false,
                    video: {
                        width: { ideal: 2560 }, // pokušaj sa što većom vrednošću
                        height: { ideal: 1440 },
                        facingMode: { ideal: facingMode }
                    }
                };
            }

            const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);


            if (video) {
                video.srcObject = mediaStream;
                video.controls = false;
                video.onloadedmetadata = function (e) {
                    video.play();
                };
            }

        } catch (err) {
            console.log(err);
        }
    }, [facingMode]);

    useEffect(() => {

        streamCamVideo();
        return () => {
            const video = document.getElementById("videoElement2") as HTMLVideoElement;
            if (video && video.srcObject) {
                const tracks = (video.srcObject as MediaStream).getTracks();
                tracks.forEach(track => track.stop());
            }
        };
    }, [streamCamVideo]);
}

export async function createJob(companyId: string, requestId: string, setReferenceNumber: any) {

    const refNumber = await getReferenceNumber(companyId, requestId);
    setReferenceNumber(refNumber);

    await fetch(`${process.env.REACT_APP_API_URL}/api/v1/request/update/paymentMethod`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            companyId: companyId,
            requestId: requestId,
            paymentMethod: "direct"

        })
    })

    await fetch(`${process.env.REACT_APP_API_URL}/api/v1/job`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            companyId: companyId,
            requestId: requestId,
            paymentMethod: "direct"
        })
    })
}

export async function checkDocumentData(image: string, requestId: string, companyId: string) {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/document/analyze`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                documentImage: image,
                requestId: requestId,
                companyId: companyId
            }),
        });

        const res = await response.json();
        return res;
    } catch (err) {
        console.error("Update request status failed: " + err);
    }
}

export async function imageComparing(documentImage: string, selfImage: string, companyId: string, requestId: string) {
    try {
        console.log(3)
        console.log('Document: ' + documentImage)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/image/compare`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                documentImage: documentImage,
                selfImage: selfImage,
                companyId: companyId,
                requestId: requestId
            }),
        });

        const res = await response.json();
        return res;

    } catch (err) {
        console.error('Image Comparing error: ' + err);
    }
}

export async function saveImage(image: string, companyId: string, requestId: string, test: boolean, testType?: string) {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/image/save`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                image: image,
                companyId: companyId,
                requestId: requestId,
                test: test,
                testType: testType
            }),
        });

        const res = await response.json();
        return res;

    } catch (err) {
        console.error('Saving Image error: ' + err);
    }
}

// export async function saveVideo(blob: Blob, companyId: string, requestId: string, format: string) {
//     try {
//         let response: any;
//         const reader = new FileReader();
//         reader.readAsDataURL(blob);
//         reader.onloadend = async () => {
//             const base64data = reader.result?.toString().split(',')[1];  // Uzimanje base64 stringa

//             response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/video`, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify({
//                     videoData: base64data,
//                     companyId: companyId,
//                     requestId: requestId,
//                     format: format
//                 }),
//             });

//             const res =  await response.json()
//             console.log('res: ' + res.status)
//             return res.status
//         }
//     } catch (err) {
//         console.error('Error uploading video:', err);
//     }
// }

export async function saveVideo(blob: Blob, companyId: string, requestId: string, format: string): Promise<boolean> {
    try {
        const base64data = await convertBlobToBase64(blob);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/video`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                videoData: base64data,
                companyId,
                requestId,
                format
            }),
        });

        const res = await response.json();

        return res.status;
    } catch (err) {
        console.error('Error uploading video:', err);
        return false;
    }
}

function convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64String = reader.result?.toString().split(',')[1];
            if (base64String) {
                resolve(base64String);
            } else {
                reject(new Error('Failed to convert blob to Base64'));
            }
        };
        reader.onerror = (error) => reject(error);
    });
}


export async function checkVideo(companyId: string, requestId: string) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/video/check`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            companyId: companyId,
            requestId: requestId
        })
    });

    return response.json();
}

export function setAccessToken(accessToken: string) {
    sessionStorage.setItem('accessToken', accessToken)
}

export function setUserAccess(userAccess: AdminAccess) {
    sessionStorage.setItem('userAccess', JSON.stringify(userAccess))
}

export function getRedirectUrl() {
    return sessionStorage.getItem('redirectUrl') ?? '/'
}

export function getAccessToken() {
    return sessionStorage.getItem('accessToken')
}

export function removeAccess() {
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('userAccess')
    sessionStorage.removeItem('companyInfo')
    sessionStorage.removeItem('redirectUrl')
}

export function showKAlert(
    variant: string,
    message: string | Array<string>
) {
    window.kAlert = {
        variant: variant,
        message: message
    }

    window.dispatchEvent(new Event('showKAlert'))
}
export function hideKAlert() {
    window.dispatchEvent(new Event('hideKAlert'))
}

export function disableClickables() {
    document.querySelectorAll('button, a, input, select, textarea')
      .forEach((item: any) => {
        item.classList.add('non-clickable')
        item.disabled = true
      })
  }