import { useEffect, useState } from "react";
import { Alert, Form, Button, Container, FormSelect, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { t } from "../locale";
import NationalityDropdown from "./NationalityField";
import { formatDate, parseDate, updateStatus } from "../helpers/helpers";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import { isMobile } from "react-device-detect";
import { format, toDate } from 'date-fns';
import PrivacyPolicyModal from '../components/PrivacyPolicyModal';
import { STATUS_INITIATED } from "../utils/constants";

export const Index = () => {
  const navigate = useNavigate();
  const [hasAccess, setHasAccess] = useState(true);
  const [expiredPage, setExpiredPage] = useState(false);
  const [space, setSpace] = useState('mb-5')
  const [spaceHeight, setSpaceHeight] = useState('mt-5')
  const [showModal, setShowModal] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false)

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get("session_id");

  const pathSegments = window.location.pathname.split("/");
  const companyId = pathSegments[1];
  const requestId = pathSegments[2];

  const currentPath = window.location.pathname;
  let navigationPath: boolean;
  if (currentPath.endsWith("/p")) {
    navigationPath = true;
  } else {
    navigationPath = false;
  }

  useEffect(() => {
    fetchData()
    updateStatus(companyId, requestId, STATUS_INITIATED)

    if (isMobile) {
      setSpace('')
      setSpaceHeight('')
    }

    checkPrivacyPolicy()
  }, []);

  const goToNextPage = ($url: string) => {
    navigate($url);
  }

  const [userData, setUserData] = useState({
    gender: "",
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    cityOfBirth: "",
    nationality: "",
    status: "",
    language: localStorage.getItem("locale"),
    idNumber: "",
    passport: ""
  });

  const checkPrivacyPolicy = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/request/data/all`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          companyId: companyId,
          requestId: requestId
        })
      }
    )

    const data = await response.json();

    if (data.privacyPolicy === true) {
      setShowModal(false)
    } else {
      setShowModal(true)
    }
  }

  const handleAccept = async () => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/request/update/requestData`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          companyId: companyId,
          requestId: requestId,
          userData: { privacyPolicy: true }
        })
      }
      
    )
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    setShowModal(false);
    window.location.reload(); 
  };

  const handleClose = () => {
    setShowModal(false);
    window.location.reload(); 
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/request/data/all`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            companyId: companyId,
            requestId: requestId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();

      if (data.status === "EXPIRED") {
        setExpiredPage(false);
      } else {
        setUserData(data);
      }

      if (data.privacyPolicy === true)
        setPrivacyPolicy(true)
    } catch (error) {
      console.error("Error fetching data:", error);
      setHasAccess(false);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      userData.language = localStorage.getItem("locale");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/request/update/requestData`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            companyId: companyId,
            requestId: requestId,
            userData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit data");
      }

      if (!navigationPath) {
        goToNextPage(`/${companyId}/${requestId}/return/?session_id=${sessionId}`);
      } else {
        goToNextPage(`/${companyId}/${requestId}/si/${userData.passport}/p`);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const initialDate = parseDate(userData.dateOfBirth);

  return (
    <>
      <div className="App">
        {/* Modal is displayed when showModal is true */}
        <PrivacyPolicyModal show={showModal} onClose={handleClose} onAccept={handleAccept} />
      </div>
      <Container className="container-fluid px-sm-5">
        <Col className="col-12 px-0 bg-white d-flex d-sm-none sticky-top z-0">
          <div className="d-flex flex-row flex-grow-1 align-items-between align-items-sm-start px-2 pe-3 pt-3">
            <ul className="nav nav-pills flex-row flex-nowrap flex-shrink-1 flex-grow-1 mb-0 justify-content-between align-items-center"
              id="menu">
              <li className="nav-item">
                <a href="index.html" className="nav-link px-sm-0 px-2">
                  <img src="/images/logo/Step 1 Active.png" className="img-fluid step-numbers" />
                </a>
              </li>
              <li>
                <a href="step2.html" className="nav-link px-sm-0 px-2">
                  <img src="/images/logo/Step 2 Next.png" className="img-fluid step-numbers" />
                </a>
              </li>
              <li>
                <a href="step3.html" className="nav-link px-sm-0 px-2">
                  <img src="/images/logo/Step 3 Next.png" className="img-fluid step-numbers" />
                </a>
              </li>
              <li>
                <a href="step4.html" className="nav-link px-sm-0 px-2">
                  <img src="/images/logo/Step 4 Next.png" className="img-fluid step-numbers" />
                </a>
              </li>
              <li>
                <a href="step5.html" className="nav-link px-sm-0 px-2">
                  <img src="/images/logo/Step 5 Next.png" className="img-fluid step-numbers" />
                </a>
              </li>
            </ul>
          </div>
        </Col>
      </Container>
      <Container hidden={expiredPage} className={`container overflow-hidden ${spaceHeight}`}>
        {hasAccess ? (
          <Row className="row overflow-auto">
            <Col className={`col-12 col-sm-3 col-xl-2 px-0 bg-white d-none d-sm-flex sticky-top pt-4 left-sidebar ${spaceHeight}`}>
              <div className={`d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-0 pt-2 text-white `}>
                <ul className="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start"
                  id="menu">
                  <li className="mb-4">
                    <a className="nav-link px-sm-0 px-2">
                      <img src="/images/logo/Step 1 Active.png"
                        className="img-fluid step-numbers" />
                      <span
                        className="ms-1 d-none d-sm-inline text-primary">{t('personalData')}</span>
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href=""
                      className="nav-link px-sm-0 px-2">
                      <img src="/images/logo/Step 2 Next.png"
                        className="img-fluid step-numbers" />
                      <span
                        className="ms-1 d-none d-sm-inline text-gray-light">{t('documentVerification')}</span>
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href=""
                      className="nav-link px-sm-0 px-2">
                      <img src="/images/logo/Step 3 Next.png"
                        className="img-fluid step-numbers" />
                      <span
                        className="ms-1 d-none d-sm-inline text-gray-light">{t('faceVerification')}</span>
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href=""
                      className="nav-link px-sm-0 px-2">
                      <img src="/images/logo/Step 4 Next.png"
                        className="img-fluid step-numbers" />
                      <span
                        className="ms-1 d-none d-sm-inline text-gray-light">{t('videoVerification')}</span>
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href=""
                      className="nav-link px-sm-0 px-2">
                      <img src="/images/logo/Step 5 Next.png"
                        className="img-fluid step-numbers" />
                      <span
                        className="ms-1 d-none d-sm-inline text-gray-light">{t('accountVerification')}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <div className={`col d-flex flex-column h-sm-100 px-sm-5 `}>
              <main className={`row overflow-auto px-sm-5`}>
                <div className={`col mt-n1 pt-4 pt-sm-5`}>
                  <h4 className="mb-4 text-start">{t('titleDataForm')}</h4>
                  <Form className="needs-validation" onSubmit={handleSubmit}>
                    <div className="row gx-2">
                      <div className={`row gx-2 ${space}`}>
                        <div className="col-sm-4">
                          <div className="form-floating mb-2">
                            <FormSelect
                              className='border-primary'
                              id='gender'
                              value={userData.gender}
                              required
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  gender: e.target.value,
                                })
                              }
                            >
                              <option value=''>--</option>
                              <option value='M'>{t('male')}</option>
                              <option value='F'>{t('female')}</option>
                              <option value='other'>{t('other')}</option>
                            </FormSelect>
                            <label
                              htmlFor="salutation">{t('gender')}</label>

                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="form-floating mb-2">
                            <Form.Control
                              className='border-primary'
                              type="text"
                              value={userData.firstName}
                              placeholder="First name"
                              required
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  firstName: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="firstName">{t('firstName')}</label>
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="form-floating mb-2">
                            <Form.Control
                              className='border-primary'
                              type="text"
                              value={userData.lastName}
                              required
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  lastName: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="lastName">{t('lastName')}</label>
                          </div>
                        </div>
                      </div>

                      <div className={`row gx-2 ${space}`}>
                        <div className="col-sm-4">
                          <div className="form-floating mb-2">
                            <Form.Control
                              className='border-primary'
                              type="email"
                              value={userData.email}
                              required
                              readOnly
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  email: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="email">{(t('email'))}</label>
                          </div>
                        </div>

                        <div className="col-sm-4">

                          <div className="form-floating mb-2">
                            {/* <label htmlFor="birthday">Birthday</label> */}
                            <DatePicker

                              className="form-control border-primary"
                              selected={parseDate(userData.dateOfBirth)}
                              required
                              dateFormat="dd.MM.yyyy"
                              title="Birthday"
                              showYearDropdown
                              showMonthDropdown
                              dropdownMode="select"
                              onChange={(date) => {
                                setUserData({
                                  ...userData,
                                  dateOfBirth: formatDate(date).toString(),
                                });
                              }}
                            />


                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="form-floating mb-2">

                            <NationalityDropdown
                              selectedNationality={userData.nationality}
                              onChange={(e: any) =>
                                setUserData({
                                  ...userData,
                                  nationality: e.target.value,
                                })
                              }
                            />
                            <label
                              htmlFor="nationality">{t('nationality')}</label>
                          </div>
                        </div>
                      </div>

                      <div className={`row gx-2 ${space}`}>
                        <div className="col-sm-4">
                          <div className="form-floating mb-2">
                            <select className="form-control border-primary"
                              id="documentType"
                              required={true}
                              value={userData.passport}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  passport: e.target.value,
                                })
                              }>
                              <option value=""></option>
                              <option value="false">{t('idCard')}</option>
                              <option
                                value="true">{t('passport')}</option>
                            </select>
                            <label htmlFor="documentType">{t('documentType')}</label>
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="form-floating mb-2">
                            <Form.Control
                              className='border-primary'
                              value={userData.idNumber}
                              required
                              onChange={(e: any) =>
                                setUserData({
                                  ...userData,
                                  idNumber: e.target.value
                                })
                              }
                            />
                            <label htmlFor="documentNumber">{t('idNumber')}</label>
                          </div>
                        </div>
                      </div>

                      <hr className="my-5" />

                      <div className="col-sm-4 offset-sm-8 text-end">
                        {privacyPolicy && <button
                          className="btn btn-success btn-lg text-white"
                          type="submit">{t('nextStep')} »</button>}
                      </div>
                    </div>
                  </Form>
                  <p className="my-5">&nbsp;</p>
                </div>
              </main>
            </div>
          </Row >
        ) : (
          <Alert variant="danger" className="mt-3">
            <div>{t("accessDenied")}</div>
          </Alert>
        )}
      </Container >
      <div hidden={!expiredPage}>
        <h2>{t("requestExpired")}</h2>
        <p>{t("accessExpired")}</p>
      </div>
    </>

  );
};

export default Index;
