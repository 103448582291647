import { useState } from 'react'
import { hideKAlert, removeAccess, setAccessToken, showKAlert } from '../helpers/helpers'
import v from '../validators'
import { Button, Card, Form, FormControl } from 'react-bootstrap'
import { t } from '../locale'
import { Link, useNavigate } from 'react-router-dom'
import { DoorOpenFill } from 'react-bootstrap-icons'

function Login(props: any) {

    const navigate = useNavigate()

    if (props.logout) {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/access`, {
            method: 'DELETE'
        }).then(() => {
            removeAccess()
            hideKAlert()
            navigate('/user/login')
        })
    }

    function getAccess() {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/access/get`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email,
                password: password
            })
        }).then(r => r.json())
            .then(r => {
                if (!!r.accessToken) {
                    setAccessToken(r.accessToken)
                    navigate('/ds_id_verification/pincode-validation')
                } else {
                    showKAlert('danger', t('incorrectPassword'))
                    setTimeout(() => {
                        hideKAlert()
                    }, 5000);
                }
            })
    }

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isEmailValid, setIsEmailValid] = useState(true)
    const [isPasswordValid, setIsPasswordValid] = useState(true)

    function handleEmail(e: any) {
        setEmail(e.target.value.trimStart())
        setIsEmailValid(v.email(email))
    }
    function handlePassword(e: any) {
        setPassword(e.target.value.trim())
        setTimeout(() => {
            setIsPasswordValid(password.trim() !== '')
        }, 0)
    }
    function handleSubmit(e: any) {
        e.preventDefault()

        setIsEmailValid(v.email(email))
        setIsPasswordValid(password.trim() !== '')

        if (v.email(email) && password.trim() !== '') {
            getAccess()
        }
    }

    return (
        <Card className='col-8 m-auto mt-4 w-25'>

            <Card.Header>
                <DoorOpenFill />
                <span className='p-1 align-middle'>
                    {t('login')}
                </span>
            </Card.Header>

            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <FormControl id='email'
                        type='email'
                        isInvalid={!isEmailValid}
                        placeholder={t('email')}
                        value={email}
                        onChange={handleEmail}
                        onKeyUp={handleEmail}
                        autoComplete='on'
                        autoFocus />

                    <FormControl id='password'
                        type='password'
                        isInvalid={!isPasswordValid}
                        placeholder={t('password')}
                        value={password}
                        onChange={handlePassword}
                        onKeyUp={handlePassword}
                        className='mt-3'
                        autoComplete='on' />

                    <div className='mt-3'>
                        <button type='submit'
                            className='btn btn-primary mt-3 text-white'
                            onClick={handleSubmit}>{t('submit')}</button>

                        <Link to='/ds_id_verification/forgot-password'
                            className='f-right'>
                            {t('forgotPassword')}
                        </Link>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default Login
