import { t } from '../locale'
import { useState, useEffect, useCallback, useRef } from 'react'
import { Container, Spinner } from 'react-bootstrap';
import { Card, Table, Dropdown, Badge, Button, ButtonGroup, Form, FormSelect, Col } from 'react-bootstrap';
import { formatDate, getAccessToken, removeAccess, updateStatus } from '../helpers/helpers';
import { useNavigate } from 'react-router-dom';

export const Index = () => {
    const navigate = useNavigate();
    const [requests, setRequests] = useState([] as Array<any>)
    const [loading, setLoading] = useState(false);
    const [filterStatus, setFilterStatus] = useState('SUBMITTED')

    const companyId = process.env.REACT_APP_COMPANY_ID

    useEffect(() => {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
            navigate('/ds_id_verification/login'); // Redirektuj na login ako korisnik nije ulogovan
        }

        getRequests()
    }, [navigate, filterStatus])

    async function getRequests() {

        const accessToken = getAccessToken();
        if (!!accessToken) {
            await apiRequest(
                `${process.env.REACT_APP_API_URL}/api/v1/request/`,
                "POST",
                {
                    companyId: companyId,
                    filter: filterStatus
                },
                '',
                accessToken
            ).then(r => {
                setRequests(r.requests)
            })
        }
    }

    async function apiRequest(url: string, method: string, body?: any, apiKey?: string, accessToken?: any) {
        const headers: Record<string, string> = {
            'Content-Type': 'application/json'
        };

        if (apiKey) {
            headers['x-api-key'] = `${apiKey}`
        }

        if (accessToken) {
            headers['accessToken'] = `${accessToken}`
        }

        const response = await fetch(url, {
            method,
            headers,
            body: body ? JSON.stringify(body) : undefined
        });

        if (response.status === 403) {
            navigate('/ds_id_verification/login')
        }

        return response.json();
    }

    async function getFile(requestId: string, createdAt: string, fileType: 'pdf' | 'mp4') {

        const fileName = fileType === 'pdf'
            ? `videoidentifikation-${createdAt.split('T')[0]}.pdf`
            : `video.mp4`;
        try {
            setLoading(true);

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/report/download/admin/${requestId}/${fileName}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    companyId: companyId,
                    requestId: requestId
                })
            });
            if (!response.ok) {
                throw new Error('Greška prilikom preuzimanja fajla.');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            // Ako je PDF, otvori u novom tabu, a ako je MP4, prikaži kao video
            if (fileType === 'pdf') {
                window.open(url, '_blank');
            } else {
                const videoWindow = window.open();
                if (videoWindow) {
                    videoWindow.document.write(`
                        <video controls autoplay style="width: 100%; height: 100%;">
                            <source src="${url}" type="video/mp4">
                            Video nije podržan u ovom browseru.
                        </video>
                    `);
                }
            }

        } catch (error) {

            console.error(error);

        } finally {
            setLoading(false);
        }

    }

    const handleStatusChange = async (id: string, newStatus: string) => {
        try {
            setLoading(true)
            if (companyId) {

                setTimeout(async () => {
                    try {
                        await updateStatus(companyId, id, newStatus);
                        setRequests(prevRequests =>
                            prevRequests.map(request =>
                                request._id === id ? { ...request, status: newStatus } : request
                            )
                        );
                    } catch (error) {
                        console.error('Error:', error);
                    } finally {
                        setLoading(false)
                    }
                }, 5000)
            }


        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleLogout = () => {
        const accessToken = getAccessToken();
        if (accessToken) {
            const headers: Record<string, string> = {
                'Content-Type': 'application/json',
                'accessToken': accessToken
            };
            fetch(`${process.env.REACT_APP_API_URL}/api/v1/access`, {
                method: 'DELETE',
                headers: headers
            }).then(() => {
                removeAccess()
                navigate('/ds_id_verification/login');
            })
        }
    };

    function handleFilterStatus(e: any) {
        setFilterStatus(e.target.value)
    }

    function handleShowAll(e: any) {
        setFilterStatus('')
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 bg-light shadow-sm border-bottom">
                <h4 className="mb-0 fw-bold text-primary">DS Identity - Data Security</h4>
                <button className="btn btn-outline-danger" onClick={handleLogout}>
                    {t('Logout')}
                </button>
            </div>
            {loading && (
                <div className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100 bg-white bg-opacity-75" style={{ zIndex: 9999 }}>
                    <Spinner animation="border" variant="primary" role="status" />
                    <span className="ms-2">{t('Loading...')}</span>
                </div>
            )}
            <Card className="shadow-sm my-4">
                <Card.Header className="bg-primary text-center py-3">
                    <h5 className="mb-0 text-white">{t('DS Identity')}</h5>
                </Card.Header>
            </Card>

            <Form className="row mt-3 align-items-center" onSubmit={handleFilterStatus}>
                <Col xs="auto" className="d-flex align-items-center">
                    <Form.Label htmlFor="filterStatus" className="fw-semibold me-2">{t('Status')}:</Form.Label>
                    <Form.Select id="filterStatus" value={filterStatus} onChange={handleFilterStatus} className="w-auto">
                        <option value="NEW">{t('NEW')}</option>
                        <option value="INITIATED">{t('INITIATED')}</option>
                        <option value="PENDING_PAYMENT">{t('PENDING_PAYMENT')}</option>
                        <option value="SUBMITTED">{t('SUBMITTED')}</option>
                        <option value="SUCCESS">{t('SUCCESS')}</option>
                    </Form.Select>
                </Col>
                <Col className="text-end">
                    <Button variant="secondary" onClick={handleShowAll} style={{ textTransform: 'none' }}>
                        {t('ShowAll')}
                    </Button>
                </Col>
            </Form>

            <Table responsive striped bordered className="mt-3 noWrap">
                <thead className="text-center">
                    <tr>
                        <th>{t('firstName')}</th>
                        <th>{t('lastName')}</th>
                        <th>{t('dateOfBirth')}</th>
                        <th>{t('email')}</th>
                        <th>{t('idNumber')}</th>
                        <th>{t('Status')}</th>
                        <th>{t('similarity')}</th>
                        <th>{t('createdBy')}</th>
                        <th>{t('createdAt')}</th>
                        <th>{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {requests && requests.length > 0 ? (
                        requests.map((request) => (
                            <tr key={request._id}>
                                <td className="text-center text-nowrap">{request.firstName}</td>
                                <td className="text-nowrap">{request.lastName}</td>
                                <td className="text-nowrap">{request.dateOfBirth}</td>
                                <td className="text-nowrap">{request.email}</td>
                                <td className="text-nowrap">{request.idNumber}</td>
                                <td className="text-nowrap">
                                    <Badge bg={request.status === 'SUCCESS' ? 'success' : 'warning'}>
                                        {request.status}
                                    </Badge>
                                </td>
                                <td className="text-nowrap">{(request.similarity * 100).toFixed(2)}%</td>
                                <td className="text-nowrap">{request.createdBy}</td>
                                <td className="text-nowrap">{formatDate(new Date(request.createdAt))}</td>
                                <td>
                                    {request.status === 'SUBMITTED' || request.status === 'SUCCESS' ? (
                                        <Dropdown>
                                            <Dropdown.Toggle variant="primary" size="sm">
                                                {t('Documents')}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu flip={false}>
                                                <Dropdown.Item onClick={() => getFile(request._id, request.createdAt, 'pdf')}>
                                                    📄 {t('PDF')}
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => getFile(request._id, request.createdAt, 'mp4')}>
                                                    🎥 {t('Video')}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : null}
                                </td>
                                <td>
                                    {request.status === 'SUBMITTED' && (
                                        <ButtonGroup>
                                            <Button
                                                variant="success"
                                                size="sm"
                                                onClick={() => handleStatusChange(request._id, 'SUCCESS')}
                                            >
                                                ✔️ {t('AcceptDocument')}
                                            </Button>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => handleStatusChange(request._id, 'FAILED')}
                                            >
                                                ❌ {t('RejectDocument')}
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={10} className="text-center py-4">
                                <em>{t('noClientsFound')}</em>
                            </td>
                        </tr>
                    )}

                    {/* Prazan red za dodatni prostor */}
                    <tr style={{ opacity: 0.5, height: '50px' }}>
                        <td colSpan={10}></td>
                    </tr>

                </tbody>
            </Table>
        </>
    )
}

export default Index;