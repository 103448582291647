import Layout from './Layout';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './ds-compliance-paid/index'
import Home2 from './ds-compliance-paid/index_passport'
import Hom from './ds-compliance-unpaid'
import { getLocale } from './locale'
import  Index  from './shared/DataForm'
import VideoForm from './shared/VideoForm';
import Payment from './shared/PaymentForm';
import FaceIdentification from './shared/FaceForm';
import AdminLogin from './shared/AdminLogin'
import AdminCheck from './shared/Admin'
import './index.scss';
import './style.css'
import SetPassword from './shared/AdminSetPassword';
import PinCode from './shared/PinCode';
import ForgotPassword from './shared/ForgotPassword';

document.documentElement.setAttribute('lang', getLocale())

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path=':companyId/:requestId/p' index element={<Index />}></Route>
          <Route path=':company_id/:id/si/false/p' index element={<Home />}></Route>
          <Route path=':company_id/:id/si/true/p' index element={<Home2 />}></Route>
          <Route path=':company_id/:id/si/payment/p' index element={<Payment />}></Route>
          <Route path=':company_id/:id' index element={<Hom />}></Route>
          <Route path=':id' index element={<Home />}></Route>
          <Route path=':companyId/:requestId/form_data' element={<Index />}/>
          <Route path=':companyId/:requestId/video' element={<VideoForm/>}/>
          <Route path=':companyId/:requestId/face_identify' element={<FaceIdentification/>}/>
          <Route path='ds_id_verification/login' element={<AdminLogin/>}/>
          <Route path='ds_id_verification' element={<AdminCheck/>}/>
          <Route path='ds_id_verification/confirm/:accessToken' element={<SetPassword reset={false} />}/>
          <Route path='ds_id_verification/password-set/:accessToken' element={<SetPassword reset={true} />} />
          <Route path='ds_id_verification/pincode-validation/' element={<PinCode />} />
          <Route path='ds_id_verification/forgot-password' element={<ForgotPassword/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
