const validators = {

    companyName: function(name: string) {
        return /^[a-zA-Z0-9\s+]{2,}/.test(name.trim())
    },

    name: function(name: string) {
        return /^[a-zA-Z ]{2,}$/.test(name.trim())
    },

    phone: function(phone: string) {

        return true

        // return phone.charAt(0) === '+'
        //     ? phone.trim().length >= 11 && !!phone.match(/^\+{0,1}49\d+$/g)
        //     : phone.trim().length >= 8 && !!phone.match(/^\d+$/g)
    },

    email: function(email: string) {
        return !!email.trim().match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)
    },

    password: function(password: string) {

        let strongRegex = new RegExp(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
        )

        return strongRegex.test(password)
    },

    pinCode: function (pinCode: string) {
        return /^\d{7}$/.test(pinCode)
    },

    gender: function (gender: string) {
        return !!gender
    }
}

export default validators
