import { Card, Container, NavDropdown, Navbar } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import { Translate } from 'react-bootstrap-icons'
import { changeLocale, locales, getLocale } from './locale'
import KAlert from './components/KAlert'
import { useState } from 'react'
import { t } from "./locale";
import './style.css'

function Layout() {

  const [kAlert, setKAlert] = useState(window.kAlert)
  const [showKAlert, setShowKAlert] = useState(false)
  const currentLocale: string = getLocale()
  function handleLocaleChange(locale: string) {
    locale !== currentLocale && changeLocale(locale)
  }

  window.addEventListener('showKAlert', () => {
    setKAlert(window.kAlert)
    setShowKAlert(true)
  })
  window.addEventListener('hideKAlert', () => {
    setShowKAlert(false)
  })

  return (
    <>

      <Card className='container-fluid px-sm-5 shadow-sm mb-1 border-0'>
        <div className='d-flex flex-wrap align-items-center justify-content-between py-3 px-2 p-sm-3 pe-sm-0 sticky-top mx-0'>

          <div className='d-flex align-items-center link-body-emphasis text-decoration-none'>
            <img alt='logo' src='/images/logo/Identity_logo_black.png' className='img-fluid navbar-logo' />
          </div>

          <Navbar className='dropdown language-dropdown text-end ms-auto'>
            <NavDropdown
              title={
                <>
                  <img
                    src={`/flags/${currentLocale}.png`}
                    alt={`${currentLocale} flag`}
                    style={{ width: '14px', height: '9px', marginRight: '4px' }}
                  />
                  {(locales as any)[currentLocale]}
                </>
              }
            >
              {Object.entries(locales).map(([locale, language]) => (
                <NavDropdown.Item key={locale} onClick={() => handleLocaleChange(locale)}>
                  <img
                    src={`/flags/${locale}.png`}
                    alt={`${language} flag`}
                    style={{ width: '14px', height: '9px', marginRight: '4px' }}
                  />
                  {language}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Navbar>

        </div>
      </Card>

      <Card.Body style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', overflowX: 'auto' }}>
        <Container fluid style={{ width: '90%', height: '80%'}}>

          <KAlert show={showKAlert}
            variant={kAlert?.variant}
            message={kAlert?.message} />
          <Outlet />
        </Container>
      </Card.Body>

      <div className="container-fluid px-sm-5 border-top fixed-bottom bg-white">
        <footer className="footer border-top border-light px-0 py-3">
          <div className="container-fluid">
            <div className="d-flex justify-content-center">
              <div className="text-center text-draft small">© 2024
                <span className="text-primary"> DS</span>|Identity by
                <a href="https://data-security.one"
                  className="text-draft link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                  target="_blank"> DATA Security GmbH</a> |
                <a
                  href="https://data-security.one/datenschutzhinweise/"
                  className="text-draft link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                  target="_blank"> Datenschutz</a> -
                <a href="https://data-security.one/impressum/"
                  className=" text-draft link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                  target="_blank"> Impressum</a>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz"
        crossOrigin="anonymous"></script>
    </>
  )
}

export default Layout