import { useEffect, useState } from "react";
import { Alert, Form, Button, Container, FormSelect, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { t } from "../locale";
import NationalityDropdown from "./NationalityField";
import { formatDate, parseDate, updateStatus } from "../helpers/helpers";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import { isMobile } from "react-device-detect";

export const Index = () => {
  const navigate = useNavigate();
  const [hasAccess, setHasAccess] = useState(true);
  const [expiredPage, setExpiredPage] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get("session_id");

  const pathSegments = window.location.pathname.split("/");
  const companyId = pathSegments[1];
  const requestId = pathSegments[2];

  const currentPath = window.location.pathname;
  let navigationPath: boolean;
  if (currentPath.endsWith("/p")) {
    navigationPath = true;
  } else {
    navigationPath = false;
  }

  const goToNextPage = ($url: string) => {
    navigate($url);
  }

  const [userData, setUserData] = useState({
    gender: "",
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    cityOfBirth: "",
    nationality: "",
    status: "",
    language: localStorage.getItem("locale"),
    idNumber: "",
  });

  useEffect(() => {
    fetchData();
    updateStatus(companyId, requestId);
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/request/data/all`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            companyId: companyId,
            requestId: requestId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();

      if (data.status === "EXPIRED") {
        setExpiredPage(false);
      } else {
        setUserData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setHasAccess(false);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      userData.language = localStorage.getItem("locale");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/request/update/requestData`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            companyId: companyId,
            requestId: requestId,
            userData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit data");
      }

      if (!navigationPath) {
        goToNextPage(`/${companyId}/${requestId}/return/?session_id=${sessionId}`);
      } else {
        goToNextPage(`/${companyId}/${requestId}/si/p`);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const initialDate = parseDate(userData.dateOfBirth);

  return (
    <>
      <Container hidden={expiredPage}>
        {hasAccess ? (
          <>
            <h4 className="text-primary py-2">{t('userData')}</h4>

            {/* Use the Bootstrap Grid system */}
            <Form onSubmit={handleSubmit}>
              <Row className="py-2">
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>{t('gender')}</Form.Label>
                    <FormSelect
                      id='gender'
                      value={userData.gender}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          gender: e.target.value,
                        })
                      }
                    >
                      <option value=''>--</option>
                      <option value='M'>{t('male')}</option>
                      <option value='F'>{t('female')}</option>
                      <option value='other'>{t('other')}</option>
                    </FormSelect>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="py-2">
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>{t('firstName')}</Form.Label>
                    <Form.Control
                      type="text"
                      value={userData.firstName}
                      required
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>{t('lastName')}</Form.Label>
                    <Form.Control
                      type="text"
                      value={userData.lastName}
                      required
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>{t('email')}</Form.Label>
                    <Form.Control
                      type="email"
                      value={userData.email}
                      required
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>{t('dateOfBirth')}</Form.Label>
                    <div>
                      <DatePicker
                        className="form-control"
                        selected={parseDate(userData.dateOfBirth)}
                        required
                        dateFormat={"dd.MM.yyyy"}
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        onChange={(date) => {
                          setUserData({
                            ...userData,
                            dateOfBirth: formatDate(date).toString(),
                          });
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>{t('nationality')}</Form.Label>
                    <NationalityDropdown
                      selectedNationality={userData.nationality}
                      onChange={(e: any) =>
                        setUserData({
                          ...userData,
                          nationality: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <Form.Group>
                    {!isMobile &&
                      <Form.Label>{t('idNumber')}</Form.Label>}
                    {isMobile &&
                      <>
                        <Form.Label>{t('idNumberMobile1')}</Form.Label><br/>
                        <Form.Label>{t('idNumberMobile2')}</Form.Label>
                      </>
                    }
                    <Form.Control
                      value={userData.idNumber}
                      required
                      onChange={(e: any) =>
                        setUserData({
                          ...userData,
                          idNumber: e.target.value
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button className='btn-success mb-2' variant="primary" type="submit">
                {t('submit')}
              </Button>
            </Form>
          </>
        ) : (
          <Alert variant="danger" className="mt-3">
            <div>{t("accessDenied")}</div>
          </Alert>
        )}
      </Container>

      <div hidden={!expiredPage}>
        <h2>{t("requestExpired")}</h2>
        <p>{t("accessExpired")}</p>
      </div>
    </>

  );
};

export default Index;
