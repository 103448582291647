import { useEffect, useCallback } from "react";
import { STATUS_INITIATED } from "../utils/constants";
/**
 *
 * Capture snapshot from web camera (document image, selfy image)
 *
 * @param video
 * @param imageType
 * @param selfImageData
 * @param documentImageData
 * @param capturedImagesCount
 * @returns
 */
export async function captureSnapshot(video: HTMLVideoElement, imageType: string, selfImageData: string, documentImageData: string) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context?.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataUrl = canvas.toDataURL('image/png')
    const imageData = dataUrl.replace(/^data:image\/png;base64,/, '');

    if (imageType === 'SELF') {
        selfImageData = imageData;
    } else if (imageType === 'DOCUMENT') {
        documentImageData = imageData;
    }

    return { selfImageData, documentImageData };
}

/**
 * Compare document and selfy images.
 *
 * @param toggleSelfIdentification
 * @param selfImageData
 * @param documentImageData
 */
export async function compare(selfImageData: string, documentImageData: string) {

    const documentImage = getCapturedImageData('DOCUMENT', selfImageData, documentImageData)
    const selfImage = getCapturedImageData('SELF', selfImageData, documentImageData)

    const pathSegments = window.location.pathname.split('/');

    if (pathSegments[1] && pathSegments[2]) {
        const companyId = pathSegments[1];
        const requestId = pathSegments[2];
        const identificationRequest = await selfIdentificationRequest(selfImage, documentImage, companyId, requestId)
        return identificationRequest;

    } else {
        console.log('ID not found in URL.');
    }

}

/**
 *
 * @param imageType
 * @param selfImageData
 * @param documentImageData
 * @returns
 */
export function getCapturedImageData(imageType: string, selfImageData: string, documentImageData: string) {
    return imageType === 'SELF' ? selfImageData : documentImageData
}

/**
 *
 * @param selfImageData
 * @param documentImageData
 * @param companyId
 * @param requestId
 * @param toggleSelfIdentification
 * @returns
 */
export async function selfIdentificationRequest(selfImageData: string, documentImageData: string, companyId: string, requestId: string) {

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/self-identification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                companyId: companyId,
                requestId: requestId,
                documentImage: documentImageData,
                selfImage: selfImageData
            })
        });

        const responseData = response.json();
        return responseData;
    } catch (error) {
        console.error('Error Self Identification: ', error);
    }

}

export async function getReferenceNumber(companyId: string, requestId: string) {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/v1/request/data", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ requestId, companyId })
        });

        if (response.ok) {
            const data = await response.json();
            return data['referenceNumber'];
        } else {
            return '';
        }
    } catch (error) {
        console.error("Error while loading reference nuber: ", error);
    }
}

export async function checkRequest(companyId: string, requestId: string): Promise<boolean> {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/v1/request/data", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ requestId, companyId })
        });
        return response.status === 404 ? false : true;
    } catch (error) {
        // Handle any errors, such as network issues or server errors
        console.error("Error while checking request:", error);
        return false;
    }
}

export function formatDate(date: Date | null) {
    if (!date) return "";
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}.${month}.${year}`
}

export function parseDate(date: string) {
    if (!date) return null;
    let [day, month, year] = date.split(/[-./]/); // Split by any of the common date separators
    return new Date(`${year}-${month}-${day}`);
}

export async function updateStatus(companyId: string, requestId: string) {
    try {
        await fetch(`${process.env.REACT_APP_API_URL}/api/v1/status/update`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                companyId: companyId,
                requestId: requestId,
                status: STATUS_INITIATED,
            }),
        });
    } catch (err) {
        console.error("Update request status failed: " + err);
    }
}

export async function CameraComponent(facingMode: any, device: any) {

    const streamCamVideo = useCallback(async () => {
        try {
            const video = document.getElementById("videoElement") as HTMLVideoElement;
            let constraints;
            if (device) {
                constraints = {
                    audio: false,
                    video: {
                        width: video.videoWidth, // pokušaj sa što većom vrednošću
                        height: video.videoHeight,
                        facingMode: { ideal: facingMode }
                    }
                };
            } else {
                constraints = {
                    audio: false,
                    video: {
                        width: { ideal: 2560 }, // pokušaj sa što većom vrednošću
                        height: { ideal: 1440 },
                        facingMode: { ideal: facingMode }
                    }
                };
            }

            const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
            

            if (video) {
                video.srcObject = mediaStream;
                video.controls = false;
                video.onloadedmetadata = function (e) {
                    video.play();
                };
            }

        } catch (err) {
            console.log(err);
        }
    }, [facingMode]);

    useEffect(() => {

        streamCamVideo();
        return () => {
            const video = document.getElementById("videoElement") as HTMLVideoElement;
            if (video && video.srcObject) {
                const tracks = (video.srcObject as MediaStream).getTracks();
                tracks.forEach(track => track.stop());
            }
        };
    }, [streamCamVideo]);
}

export async function createJob(companyId: string, requestId: string, setReferenceNumber: any) {

    const refNumber = await getReferenceNumber(companyId, requestId);
    setReferenceNumber(refNumber);

    await fetch(`${process.env.REACT_APP_API_URL}/api/v1/request/update/paymentMethod`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            companyId: companyId,
            requestId: requestId,
            paymentMethod: "direct"

        })
    })

    await fetch(`${process.env.REACT_APP_API_URL}/api/v1/job`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            companyId: companyId,
            requestId: requestId,
            paymentMethod: "direct"
        })
    })
}

export async function checkDocumentData(image: string, requestId: string, companyId: string) {
    try {

        // const data = getCapturedImageData('DOCUMENT', '', image)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/document/analyze`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                documentImage: image,
                requestId: requestId,
                companyId: companyId
            }),
        });

        const res = await response.json();
        return res;
    } catch (err) {
        console.error("Update request status failed: " + err);
    }
}

export async function imageComparing(documentImage: string, selfImage: string, companyId: string, requestId: string) {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/image/compare`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                documentImage: documentImage,
                selfImage: selfImage,
                companyId: companyId,
                requestId: requestId
            }),
        });

        const res = await response.json();
        return res;

    } catch (err) {
        console.error('Image Comparing error: ' + err);
    }
}

export async function saveImage(image: string, companyId: string, requestId: string, test: boolean, testType?: string) {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/image/save`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                image: image,
                companyId: companyId,
                requestId: requestId,
                test: test,
                testType: testType
            }),
        });

        const res = await response.json();
        return res;

    } catch (err) {
        console.error('Saving Image error: ' + err);
    }
}

export async function saveVideo(blob: Blob, companyId: string, requestId: string, format: string) {
    try {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
            const base64data = reader.result?.toString().split(',')[1];  // Uzimanje base64 stringa

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/upload/video`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    videoData: base64data,
                    companyId: companyId,
                    requestId: requestId,
                    format: format
                }),
            });
        }

    } catch (err) {
        console.error('Error uploading video:', err);
    }
}