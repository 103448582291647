import { useState } from 'react'
import { getAccessToken, hideKAlert, setAccessToken, setUserAccess, showKAlert } from '../helpers/helpers'
import v from '../validators'
import { Card, Form, FormControl, Button } from 'react-bootstrap'
import { t } from '../locale'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { DoorOpenFill } from 'react-bootstrap-icons'

function PinCode() {

    const navigate = useNavigate()

    const params: Readonly<Params<string>> = useParams()

    const [pinCode, setPinCode] = useState(params.pinCode)
    const [isValidPin, setIsValidPin] = useState(true)

    function pinCodeValidation() {
        const accessToken = getAccessToken()
        !!accessToken &&
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/pincode-validation`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'accessToken': accessToken },
            body: JSON.stringify({
                pinCode: pinCode
            })
        }).then(r => r.json())
            .then(r => {
                if (!!r.accessToken) {
                    setAccessToken(r.accessToken)
                    setUserAccess(r.userAccess)
                    hideKAlert()
                    navigate('/ds_id_verification')
                } else {
                    showKAlert('danger', t('invalidPinCode'))
                    setTimeout(() => {
                        hideKAlert()
                    }, 5000);
                }
            })
    }

    function handlePin(e: any) {
        setPinCode(e.target.value.trimStart())
        setIsValidPin(v.pinCode(pinCode as string))
    }
    function handleSubmit(e: any) {
        e.preventDefault()

        setIsValidPin(v.pinCode(pinCode as string))

        if (v.pinCode(pinCode as string)) {
            pinCodeValidation()
        }
    }

    return (
        <Card className='col-8 m-auto mt-4 w-25'>

            <Card.Header>
                <DoorOpenFill />
                <span className='p-1 align-middle'>
                    {t('pinCodeValidation')}
                </span>
            </Card.Header>

            <Card.Body>
                <Form onSubmit={handleSubmit}>

                    <FormControl id='pinCode'
                        isInvalid={!isValidPin}
                        placeholder={t('pinCode')}
                        value={pinCode}
                        onChange={handlePin}
                        onKeyUp={handlePin}
                        autoComplete='off'
                        autoFocus />

                    <button id='btn-send-pin'
                        type='submit'
                        className='btn btn-success mt-3 text-white'
                        onSubmit={handleSubmit} >{t('submit')}</button>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default PinCode
