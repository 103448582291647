import { useEffect, useState } from 'react'
import { getAccessToken, setAccessToken } from '../helpers/helpers'
import v from '../validators'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { Card, FormControl, Form, Button } from 'react-bootstrap'
import { t } from '../locale'
import { DoorOpenFill } from 'react-bootstrap-icons'
import 'bootstrap/dist/css/bootstrap.min.css';

function SetPassword(props: any) {

  const navigate = useNavigate()

  const params: Readonly<Params<string>> = useParams()

  setAccessToken(params.accessToken as string)

  const [password, setPassword] = useState(process.env.REACT_APP_SET_PASSWORD as string)
  const [repeatPassword, setRepeatPassword] = useState(process.env.REACT_APP_SET_PASSWORD as string)
  const [isPasswordValid, setIsPasswordValid] = useState(true)
  const [isRepeatPasswordValid, setIsRepeatPasswordValid] = useState(true)

  function isPasswordsEqual() {
    return password === repeatPassword
  }

  function submitPassword() {
    const accessToken = getAccessToken()

    if (!!accessToken) {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/password-set`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'accessToken': accessToken },
        body: JSON.stringify({
          password: password,
          repeatPassword: repeatPassword,
        }),
      })
        .then(r => r.json())
        .then(r => {
          console.log(4)
          if (!!r.accessToken) {
            console.log(5)
            setAccessToken(r.accessToken)
            navigate('/ds_id_verification/pincode-validation')
          }
        })
    }
  }

  useEffect(() => {
    // if (!props.reset) {
    if (!params.accessToken)
      return
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/confirm`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'accessToken': params.accessToken }
    }
    )
      .then(r => r.json())
      .then(r => {
        if (!!r.accessToken) {
          console.log('tok1: ' + r.accessToken)
          setAccessToken(r.accessToken)
        }
        console.log('tok: ' + r.accessToken)
      })
    // }
  }, [props.reset])

  function handlePassword(e: any) {
    setPassword(e.target.value.trim())
    setIsPasswordValid(v.password(password))
  }
  function handleRepeatPassword(e: any) {
    setRepeatPassword(e.target.value.trim())
    setIsRepeatPasswordValid(isPasswordsEqual())
  }
  function handleSubmit(e: any) {
    e.preventDefault()

    setIsPasswordValid(v.password(password))
    setIsRepeatPasswordValid(isPasswordsEqual())

    if (v.password(password) && isPasswordsEqual()) {
      submitPassword()
    }
  }

  return (
    <Card className='col-8 m-auto mt-4 w-25'>

      <Card.Header>
        <DoorOpenFill />
        <span className='p-1 align-middle'>
          {t('setPassword')}
        </span>
      </Card.Header>

      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <FormControl id='password'
            type='password'
            isInvalid={!isPasswordValid}
            placeholder={t('password')}
            value={password}
            onChange={handlePassword}
            onKeyUp={handlePassword}
            autoComplete='off'
            autoFocus />

          <FormControl id='repeatPassword'
            type='password'
            isInvalid={!isRepeatPasswordValid}
            placeholder={t('repeatPassword')}
            value={repeatPassword}
            onChange={handleRepeatPassword}
            onKeyUp={handleRepeatPassword}
            className='mt-3'
            autoComplete='off' />

          <button id=''
            type='submit'
            className='btn btn-success mt-3 text-white'
            onClick={handleSubmit}>{t('submit')}</button>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default SetPassword
